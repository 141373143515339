import { useState, useEffect } from 'react';
import { fetchNotification, fetchCategory, fetchUser, fetchActiveUser, fetchProduct } from '../services/apiService';

let sharedData = {
  dashd: [],
  device: [],
  notify: [],
  cat: [],
  pro: [],
  userx: null,
  loading: true,
  error: null,
};
let listeners = [];
let fetchPromise = null;

const useSharedDashboardData = () => {
  const [state, setState] = useState(sharedData);

  const fetchData = async () => {
    try {
      // Trigger loading state while data is being fetched
      sharedData = { ...sharedData, loading: true };

      // Make all API calls in parallel
      const [notifications, category, activeUser, user, products] = await Promise.all([
        fetchNotification(),
        fetchCategory(),
        fetchActiveUser(),
        fetchUser(),
        fetchProduct(),
      ]);

      // Update the shared data
      sharedData = {
        notify: notifications,
        cat: category,
        dashd: activeUser,
        userx: user,
        pro: products,
        loading: false,
        error: null, // Clear errors on successful fetch
      };
    } catch (error) {
      // Handle errors by setting the error state
      sharedData = { ...sharedData, error, loading: false };
    }

    // Notify all listeners (components using this hook)
    listeners.forEach(listener => listener(sharedData));
  };

  useEffect(() => {
    if (!fetchPromise) {
      fetchPromise = fetchData().finally(() => {
        fetchPromise = null; // Reset promise when done
      });
    }

    const listener = (data) => setState(data);
    listeners.push(listener);

    // Cleanup listener on unmount
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  }, []);

  // Function to refetch data (can be called manually)
  const refetch = () => {
    fetchData();
  };

  return { ...state, refetch };
};

export default useSharedDashboardData;
