 
import React from 'react';
import useSharedDashboardData from '../../hooks/useSharedDashboardData';

function LifetimeUser() {
  const { dashd, loading, error } = useSharedDashboardData(); 
 
  if (loading) {
    return <div className="loading"><span></span> <span></span> <span></span></div>;
  }

  if (error) {
    return <p>Error loading products: {error.message}</p>;
  }

  return (
    <> 
      <div className="custUser"> 
        <span><i className="fa fa-users"></i> </span>
        <h2>Total User</h2> 
        <h1>{dashd?.totalUser?.count ?? 'N/A'}</h1>
      </div>
    </>
  );
}

export default LifetimeUser;
